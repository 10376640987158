import React from 'react';
import SEOHelmet from '../SEOHelmet';

const HapurPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    //eslint-disable-next-line
  }, [])

  return (
    <div className='mt-24 mb-20 max-w-4xl mx-auto px-4'>
      <SEOHelmet 
       
      />
      <h1 className='text-3xl md:text-5xl font-bold text-center mb-8'>Dr. Gangesh Gunjan: Hapur's Premier Brain and Spine Surgeon</h1>
      
      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>World-Class Neurosurgical Care in Hapur</h2>
        <p className='text-lg'>Dr. Gangesh Gunjan brings cutting-edge neurosurgical expertise to Hapur, offering advanced treatments for both adults and children. With a focus on minimally invasive techniques and personalized care, Dr. Gunjan is transforming neurological health outcomes in the region.</p>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Innovative Brain and Spine Treatments</h2>
        <ul className='list-disc pl-6 text-lg'>
          <li>Awake brain surgery for eloquent cortex tumors</li>
          <li>Minimally invasive spine surgery (MISS) for faster recovery</li>
          <li>Neuroendoscopy for hydrocephalus and intraventricular tumors</li>
          <li>Spinal cord stimulation for chronic pain management</li>
          <li>Computer-assisted navigation for precise brain tumor removal</li>
          <li>Robot-assisted spine surgery for improved accuracy</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Comprehensive Neurological Care</h2>
        <p className='text-lg'>Our Hapur clinic offers expert treatment for a wide range of neurological conditions:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>Traumatic brain and spinal cord injuries</li>
          <li>Cerebrovascular disorders (aneurysms, AVMs)</li>
          <li>Degenerative spine conditions and herniated discs</li>
          <li>Epilepsy and movement disorders</li>
          <li>Brain and spine tumors</li>
          <li>Pediatric neurological disorders</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Multidisciplinary Neuro-Oncology Center</h2>
        <p className='text-lg'>Our Hapur neuro-oncology team, led by Dr. Gunjan, collaborates with top oncologists and radiation therapists to provide comprehensive care for brain and spine tumors. We offer:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>Gamma Knife radiosurgery for non-invasive tumor treatment</li>
          <li>Immunotherapy and targeted molecular therapies</li>
          <li>Intraoperative MRI for real-time tumor visualization</li>
          <li>Personalized treatment plans for optimal outcomes</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Pediatric Neurosurgery Excellence</h2>
        <p className='text-lg'>Dr. Gunjan specializes in treating complex neurological conditions in children, including:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>Congenital brain and spine malformations</li>
          <li>Pediatric brain and spinal cord tumors</li>
          <li>Spina bifida and tethered cord syndrome</li>
          <li>Hydrocephalus and shunt procedures</li>
        </ul>
        <p className='text-lg mt-4'>Our child-friendly facilities and compassionate care ensure a comfortable experience for our youngest patients and their families.</p>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Schedule Your Consultation in Hapur</h2>
        <p className='text-lg'>Experience the pinnacle of neurological care with Dr. Gangesh Gunjan, Hapur's leading neurosurgeon. To book an appointment, please call <a href="tel:+917727834367" className='text-blue-600 hover:underline'>+91-7727834367</a> or visit our clinic at Fortis Hospital, Noida.</p>
      </section>
    </div>
  );
};

export default HapurPage;