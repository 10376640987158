import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useForm, ValidationError } from '@formspree/react';

interface AppointmentModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const OnlineModal: React.FC<AppointmentModalProps> = ({ isOpen, onClose }) => {
  const [state, handleSubmit] = useForm('mkgwlqzj');
 
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    date: '',
    text: '',
    whatsapp: ''
  });

  useEffect(() => {
    if (state.succeeded) {
      const timer = setTimeout(() => {
        onClose();
        setFormData({
          name: '',
          phone: '',
          email: '',
          date: '',
          text: '',
          whatsapp: ''
        });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [state.succeeded, onClose]);

  if (!isOpen) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFinalSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(formData);
    sendWhatsAppMessage();
  };

  const sendWhatsAppMessage = () => {
    const message = `New Appointment Request:
    Name: ${formData.name}
    Phone: ${formData.phone}
    Email: ${formData.email}
    Date: ${formData.date}
    Query : ${formData.text}`;

    const whatsappNumber = '917727834367';
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  }

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-blue-900 bg-opacity-75 flex items-center justify-center p-4 z-50"
        >
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            className="bg-blue-100 rounded-lg p-4 sm:p-6 w-full max-w-md max-h-[90vh] overflow-y-auto shadow-lg"
          >
            <h2 className="text-xl sm:text-2xl font-bold mb-4 text-center font-fraunces-slab text-blue-800">Book Online Consultation</h2>
            {state.succeeded ? (
              <p className="text-blue-600 text-center font-work-sans">Your appointment has been booked successfully!</p>
            ) : (
              <form onSubmit={handleFinalSubmit} className="space-y-3 sm:space-y-4 font-work-sans">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-blue-700">Name</label>
                  <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required className="mt-1 block w-full border border-blue-300 rounded-md shadow-sm p-2 bg-white text-blue-800 focus:ring-blue-500 focus:border-blue-500" />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-blue-700">Email</label>
                  <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required className="mt-1 block w-full border border-blue-300 rounded-md shadow-sm p-2 bg-white text-blue-800 focus:ring-blue-500 focus:border-blue-500" />
                  <ValidationError prefix="Email" field="email" errors={state.errors} />
                </div>
                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-blue-700">Phone</label>
                  <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} className="mt-1 block w-full border border-blue-300 rounded-md shadow-sm p-2 bg-white text-blue-800 focus:ring-blue-500 focus:border-blue-500" />
                </div>
                <div>
                  <label htmlFor="date" className="block text-sm font-medium text-blue-700">Preferred Date</label>
                  <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} required className="mt-1 block w-full border border-blue-300 rounded-md shadow-sm p-2 bg-white text-blue-800 focus:ring-blue-500 focus:border-blue-500" />
                </div>
                <div>
                  <label htmlFor="text" className="block text-sm font-medium text-blue-700">Message</label>
                  <textarea id="text" name="text" rows={3} value={formData.text} onChange={handleChange} className="mt-1 block w-full border border-blue-300 rounded-md shadow-sm p-2 bg-white text-blue-800 focus:ring-blue-500 focus:border-blue-500"></textarea>
                </div>
                
                <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2 mt-4">
                  <button type="button" onClick={onClose} className="w-full sm:w-auto px-4 py-2 border border-blue-300 rounded-md text-sm font-medium text-blue-700 hover:bg-blue-50 transition-colors duration-300">
                    Cancel
                  </button>
                  <button type="submit" className="w-full sm:w-auto px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300">
                    Book Appointment
                  </button>
                </div>
              </form>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default OnlineModal;
