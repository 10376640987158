import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Star, Award } from 'lucide-react';


const testimonials = [
  {
    avatarImage: "/images/avatar1.png",
    name: "Sagar prj",
    rating: 5,
    review: "Exceptional Care by Dr. Gangesh Gunjan.He performed my mother's neurosurgery with the utmost professionalism and skill. He not only ensured a smooth procedure but also treated her with genuine kindness, making her feel like a friend rather than just a patient. His expertise, combined with a compassionate approach, gave us confidence throughout the entire process. We are truly grateful for his exceptional care."
  },
  {
    avatarImage: "/images/avatar2.png",
    name: "Sumit Shrivastava",
    rating: 5,
    review: "I underwent a minimally invasive procedure with Dr. Gunjan for my back pain. The results were fantastic, and his staff was professional. After knocking on many doors, I can safely say sir is among one of the best neurosurgeon in Delhi NCR."
  },
  {
    avatarImage: "/images/avatar3.png",
    name: "Subham Yadav",
    rating: 5,
    review: "Dr. Gunjan’s approach to treating my father's condition was professional and effective. He explained the process thoroughly, and even the costs were reasonable. Definitely one of the best neurosurgeon in Noida, if not in Delhi."
  },
  {
    avatarImage: "/images/avatar4.png",
    name: "Pranita Bhosale",
    rating: 5,
    review: "Best doctor!! His speech cures half the disease, his face looks like a slow god....Mother's operation was done very well..."
  },
  {
    avatarImage: "/images/avatar5.png",
    name: "Praveen Jain",
    rating: 5,
    review: "Undoubtedly, one of the best doctor in Noida. One of my relative referred me to Dr gangesh for my neurological issues and sir literally changed my life. He gave full time and explained everything in detail. Highly recommended."
  }
];

const Testimonials: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (
    <motion.section
      id="testimonials"
      className="bg-[#e8f8fa] py-16 px-4 h-[100%] sm:px-6 lg:px-8 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-5xl text-gray-900 font-semibold text-center mb-16">
          Patients Testimonials
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 relative">
          <div className="col-span-1 shadow-2xl rounded-full">
            <img
              src="/images/testimonials.png"
              alt="Dr. Gangesh Gunjan"
              className="w-full h-full object-cover filter brightness-30"
            />
          </div>
          <div className="lg:col-span-2 relative">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentIndex}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
                className="bg-white rounded-lg shadow-xl p-6 md:p-8 h-full"
              >
                <div className="flex flex-col h-full">
                  <div className="flex items-center mb-4">
                    <img
                      src={testimonials[currentIndex].avatarImage}
                      alt={testimonials[currentIndex].name}
                      className="w-16 h-16 rounded-full mr-4"
                    />
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        {testimonials[currentIndex].name}
                      </h3>
                      <div className="flex items-center">
                        {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                          <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                        ))}
                      </div>
                    </div>
                  </div>
                  <p className="text-gray-600 italic flex-grow overflow-y-auto mb-4">
                    "{testimonials[currentIndex].review}"
                  </p>
                  <div className="flex justify-between items-center mt-auto">
                    <button
                      onClick={handlePrev}
                      className="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
                    >
                      <ChevronLeft className="w-6 h-6 text-gray-600" />
                    </button>
                    <div className="flex space-x-2">
                      {testimonials.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => setCurrentIndex(index)}
                          className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-600' : 'bg-gray-300'
                            }`}
                        />
                      ))}
                    </div>
                    <button
                      onClick={handleNext}
                      className="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
                    >
                      <ChevronRight className="w-6 h-6 text-gray-600" />
                    </button>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
        <motion.div
          className="w-[80vw] md:w-[50vw] mx-auto mt-24 py-4 px-2"
        >
          <motion.div
            className="flex flex-col md:flex-row justify-between items-center space-y-8 md:ring-1 md:ring-slate-600 md:space-y-0 md:bg-transparent md:space-x-8 md:backdrop-filter md:backdrop-blur-md md:py-6 md:px-8 md:rounded-lg md:shadow-md"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <motion.div
              className="text-center"
              whileHover={{ y: -5 }}
            >
              <p className="text-3xl md:text-4xl font-bold text-blue-900">12+</p>
              <p className="text-base md:text-lg text-gray-600">Years Experience</p>
            </motion.div>
            <motion.div
              className="text-center"
              whileHover={{ y: -5 }}
            >
              <div className="flex items-center justify-center mb-1">
                <Star className="w-6 h-6 md:w-8 md:h-8 text-yellow-400 fill-current" />
                <span className="text-2xl md:text-3xl font-bold ml-2">4.9</span>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-2xl md:text-3xl font-bold">
                  <span className="text-blue-600">G</span>
                  <span className="text-red-600">o</span>
                  <span className="text-yellow-600">o</span>
                  <span className="text-blue-600">g</span>
                  <span className="text-green-600">l</span>
                  <span className="text-red-600">e</span>
                </span>
              </div>
            </motion.div>
            <motion.div
              className="text-center"
              whileHover={{ y: -5 }}
            >
              <Award className="w-10 h-10 md:w-12 md:h-12 text-[#4B0082] mx-auto mb-2" />
              <p className="text-base md:text-lg text-gray-600">Gold Medalist</p>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Testimonials;