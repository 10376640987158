import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../index.css';

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSmoothScroll = (e: React.MouseEvent<HTMLAnchorElement>, target: string) => {
    e.preventDefault();
    const element = document.getElementById(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>, targetSection: string) => {
    e.preventDefault();
    if (window.location.pathname === '/') {
      handleSmoothScroll(e, targetSection);
    } else {
      window.location.href = `/#${targetSection}`;
    }
    setIsMobileMenuOpen(false);
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 text-gray-800 transition-all duration-300 ${isScrolled ? 'shadow-md bg-white' : 'bg-white'}`}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          <Link to="/" className="flex items-center space-x-3">
            <img
              src='/images/logo.png'
              alt="Dr. Gangesh Gunjan"
              className="h-12 w-auto"
            />
            <div className='flex flex-col'>
              <h1 className='text-lg md:text-xl lg:text-2xl font-semibold'>Dr. Gangesh Gunjan</h1>
              <p className='text-xs lg:text-sm'>M.B.B.S, MS (Gen Surg), M.Ch (Neurosurgery)</p>
            </div>
          </Link>
          <nav className="hidden lg:flex items-center space-x-6">
            <NavLink to="/" label="Home" />
            <NavLink to="/#about" label="About" onClick={(e) => handleNavigation(e, 'about')} />
            <NavLink to="/#services" label="Services" onClick={(e) => handleNavigation(e, 'services')} />
            <NavLink to="/#blog" label="Blog" onClick={(e) => handleNavigation(e, 'blog')} />
            <Link
              to="/#contact"
              className="text-white bg-blue-800 hover:bg-blue-700 px-4 py-2 rounded-md transition-colors duration-300"
              onClick={(e) => handleNavigation(e, 'contact')}
            >
              Schedule Appointment
            </Link>
          </nav>
          <button
            className="lg:hidden text-gray-600 hover:text-blue-600 focus:outline-none"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? (
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            )}
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="lg:hidden bg-white shadow-md">
          <div className="container mx-auto px-4 py-2">
            <NavLink to="/" label="Home" mobile onClick={() => setIsMobileMenuOpen(false)} />
            <NavLink to="/#about" label="About" mobile onClick={(e) => handleNavigation(e, 'about')} />
            <NavLink to="/#services" label="Services" mobile onClick={(e) => handleNavigation(e, 'services')} />
            <NavLink to="/#blog" label="Blog" mobile onClick={(e) => handleNavigation(e, 'blog')} />
            <Link
              to="/#contact"
              className="block text-center mt-2 text-white bg-blue-800 hover:bg-blue-700 px-4 py-2 rounded-md transition-colors duration-300"
              onClick={(e) => handleNavigation(e, 'contact')}
            >
              Schedule Appointment
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

const NavLink: React.FC<{ to: string; label: string; onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void; mobile?: boolean }> = ({ to, label, onClick, mobile }) => (
  <Link
    to={to}
    className={`text-gray-800 hover:text-blue-600 font-medium ${mobile ? 'block py-2' : ''}`}
    onClick={onClick}
  >
    {label}
  </Link>
);

export default Header;