import React, { useEffect } from "react";
import { ServiceCard } from "./BrainServices";
import servicesData from "../components/utility/ServiceData";
import { Helmet } from "react-helmet";


const NerveServices: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        //eslint-disable-next-line
    }, [])
    const serviceEnteries = Object.entries(servicesData).slice(14, 17);
    return (
        <>
            <Helmet>
                <title>Nerve Surgeon in Noida & Delhi NCR | Neurosurgeon at Fortis Hospital</title>
                <meta name="description" content="Nerve Surgeon in Noida with experience of over 12 years. Best Neurosurgeon for Sciatica, Carpal Tunnel Syndrome, Peripheral Nerve Disorders etc. Call Now." />
                <meta name="keywords" content="neurosurgeon, Delhi, Dr. Gangesh Gunjan, brain surgery, spine surgery, neurological disorders, neurosurgery, top neurosurgeon, experienced neurosurgeon" />
            </Helmet>

            <div className="bg-gradient-to-b from-gray-50 to-white">
                <div className="container mx-auto px-4 py-16 mt-28">
                    <div className="max-w-4xl mx-auto mb-16">
                        <h2 className="text-4xl font-bold mb-12 text-center text-blue-900">Nerve Services</h2>
                        
                        <div className="space-y-8 text-lg leading-relaxed">
                            <p className="bg-white p-6 rounded-lg shadow-sm border-l-4 border-blue-800">
                            In some cases, nerve surgery is the best option for lasting relief after physical therapy and non-surgical methods fail. Dr Gangesh Gunjan’s experience and expertise in the domain makes him one of the best neurosurgeons in Noida & Delhi NCR. Surgery options for each case are chosen after careful consideration of your functional goals and medical history. 
                            </p>
                            
                            <p className="bg-white p-6 rounded-lg shadow-sm">
                            After the agreeing on the best way forward, the team discusses in detail with you the benefits, risks and the options ahead. Whether it is Sciatica, Carpal Tunnel Syndrome or any peripheral nerve disorder, we’re here to help you with the decision making process and guide you throughout the treatment journey. Get in touch right now.
                            </p>
                            
                           
                        </div>
                    </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {serviceEnteries.map(([slug, service]) => (
                        <ServiceCard
                            key={slug}
                            title={service.title}
                            description={service.description}
                            slug={slug}
                        />
                    ))}
                </div>
            </div>
            </div>
        </>
    )
}

export default NerveServices;