import React from 'react';
import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout/Layout';
import Home from './pages/Home';
import About from './pages/About';
import BrainServices from './pages/BrainServices';
import ServiceTemplatePage from './components/utility/ServicePageTemplate';
import SpineServices from './pages/SpineServices';
import NerveServices from './pages/NerveServices';
import Blog1 from './components/utility/blog1';
import Blog2 from './components/utility/blog2';
import Blog3 from './components/utility/blog3';

// Import new location-specific components
import BishanpuraPage from './pages/BishanpuraPage';
import PrithviViharPage from './pages/PrithviViharPage';
import HapurPage from './pages/HapurPage';
import BulandshahrPage from './pages/BulandshahrPage';
import GreaterNoidaPage from './pages/GreaterNoidaPage';

import './index.css';

const App: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/top-neurosurgeon-in-bishanpura' element={<BishanpuraPage />} />
        <Route path='/expert-neurosurgeon-prithvi-vihar' element={<PrithviViharPage />} />
        <Route path='/best-brain-and-spine-surgeon-hapur' element={<HapurPage />} />
        <Route path='/leading-neurosurgeon-bulandshahr' element={<BulandshahrPage />} />
        <Route path='/best-neurosurgeon-in-greater-noida' element={<GreaterNoidaPage />} />
        <Route path='/about' element={<About />} />
        <Route path='/services/:serviceId' element={<ServiceTemplatePage />} />
        <Route path='/services/brain-services' element={<BrainServices />} />
        <Route path='/services/spine-services' element={<SpineServices />} />
        <Route path='/services/nerve-services' element={<NerveServices />} />
        <Route path='/blogs/blog1' element={<Blog1 />} />
        <Route path='/blogs/blog2' element={<Blog2 />} />
        <Route path='/blogs/blog3' element={<Blog3 />} />
      </Routes>
    </Layout>
  );
}

export default App;