import React from 'react';
import SEOHelmet from '../SEOHelmet';

const GreaterNoidaPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    //eslint-disable-next-line
  }, [])
  
  return (
    <div className='mb-20 mt-28 max-w-4xl mx-auto px-4'>
      <SEOHelmet  />
      <h1 className='text-3xl md:text-5xl font-bold text-center mb-8'>Dr. Gangesh Gunjan: Greater Noida's Premier Neurosurgeon</h1>
      
      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Advanced Neurosurgical Care in Greater Noida</h2>
        <p className='text-lg'>Dr. Gangesh Gunjan brings world-class neurosurgical expertise to Greater Noida, offering state-of-the-art treatments for complex neurological conditions. With a focus on minimally invasive techniques and personalized care, Dr. Gunjan is revolutionizing neurosurgical practices in the region.</p>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Cutting-Edge Neurosurgical Treatments</h2>
        <ul className='list-disc pl-6 text-lg'>
          <li>Laser Interstitial Thermal Therapy (LITT) for deep-seated brain tumors</li>
          <li>Micro-endoscopic discectomy for herniated discs</li>
          <li>Vagus Nerve Stimulation (VNS) for epilepsy management</li>
          <li>Neuronavigation-guided brain biopsies and tumor resections</li>
          <li>Endovascular treatments for stroke and aneurysms</li>
          <li>Deep Brain Stimulation (DBS) for movement disorders</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Comprehensive Neurological Services</h2>
        <p className='text-lg'>Dr. Gunjan's clinic in Greater Noida is equipped with advanced neuroimaging facilities and offers expert care for a wide range of conditions:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>Brain aneurysms and arteriovenous malformations (AVMs)</li>
          <li>Chiari malformation and syringomyelia</li>
          <li>Complex spinal deformities and scoliosis</li>
          <li>Peripheral nerve disorders and entrapment syndromes</li>
          <li>Trigeminal neuralgia and facial pain disorders</li>
          <li>Pituitary tumors and skull base lesions</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>State-of-the-Art Neuro-Oncology Center</h2>
        <p className='text-lg'>Our neuro-oncology program in Greater Noida combines surgical expertise with the latest targeted therapies:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>Awake craniotomy for eloquent cortex tumors</li>
          <li>Fluorescence-guided surgery for improved tumor resection</li>
          <li>Stereotactic radiosurgery for brain metastases</li>
          <li>Immunotherapy and personalized molecular treatments</li>
          <li>Intraoperative neurophysiological monitoring</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>24/7 Neurotrauma and Emergency Care</h2>
        <p className='text-lg'>As a leading trauma neurosurgeon, Dr. Gunjan provides round-the-clock emergency care for:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>Traumatic brain injuries and concussions</li>
          <li>Spinal cord injuries and vertebral fractures</li>
          <li>Acute stroke intervention</li>
          <li>Intracranial hemorrhages and hematomas</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Patient-Centric Approach</h2>
        <p className='text-lg'>Dr. Gunjan believes in empowering patients through education and shared decision-making. Our clinic offers:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>Comprehensive pre-operative counseling</li>
          <li>Customized post-operative rehabilitation programs</li>
          <li>Virtual consultations for remote patients</li>
          <li>Support groups for patients and caregivers</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Schedule Your Consultation in Greater Noida</h2>
        <p className='text-lg'>Take the first step towards advanced neurological care with Dr. Gangesh Gunjan, Greater Noida's premier neurosurgeon. To book an appointment, please call <a href="tel:+917727834367" className='text-blue-600 hover:underline'>+91-7727834367</a> or visit our clinic at Fortis Hospital, Noida.</p>
      </section>
    </div>
  );
};

export default GreaterNoidaPage;